<template>
  <div id="barcode" style="width: 100%;"></div>
</template>
<script>
export default {
  props: ["line"],
  async mounted() {
    var scripts = [
      "/boleto/js/boleto.min.js",
    ];

    // Função para carregar um script e retornar uma Promise
    function loadScript(src) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.type = "text/javascript";
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    }

    // load scripts await loading
    for (let i = 0; i < scripts.length; i++) {
      await loadScript(scripts[i]);
    }

    new Boleto(this.line).toSVG('#barcode');
  }
};
</script>