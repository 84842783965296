<template>
  <div class="order-approved-content">
    <h1>Agora é só pagar com o código Pix abaixo:</h1>
    <v-divider></v-divider>
    <div class="order-approved-content-value">
      <p>Valor do Pix</p>
      <p>
        <strong style="color: rgba(var(--theme-primaria));">R$ {{ formatterHelper.formatMoney(this.selectedInstallment.total_value) }}</strong>
      </p>
    </div>
    <div>
      <img v-if="!data.charges[0].base_64" width="226px" :src="data.charges[0].qr_code_url" :alt="`QR Code`" />
      <img v-else width="226px" :src="'data:image/png;base64,'+data.charges[0].qr_code" :alt="`QR Code`" />
    </div>

    <!-- <div class="order-approved-content-expiration">
      <p>O código expira em</p>
      <p>
        <strong>12:54</strong>
      </p>
    </div> -->

    <p>
      <strong>Ou copie e cole o código PIX</strong>
    </p>

    <div class="order-approved-content-copy">
      {{ data.charges[0].qr_code_copy_and_paste }}
    </div>

    <div class="order-approved-content-copy-action">
      <v-btn @click="copyPixCode" class="btn btn-primary full"
      :style="!disableButton ? {'background-color': 'rgba(var(--theme-primaria)) !important'} : {}"
      >
        <i class="fal fa-copy"></i>
        Copiar código Pix
      </v-btn>
    </div>
  </div>
</template>

<script>
// VUEX
import { mapGetters } from "vuex";

// Helpers
import FormatterHelper from "@/scripts/helpers/formatter.helper";

export default {
  props: ["params","data"],
  data() {
    return {
      formatterHelper: new FormatterHelper(),
    };
  },
  computed: {
    ...mapGetters([
      "checkoutValue",
      "selectedInstallment"
    ]),
  },
  methods: {
    copyPixCode() {
      navigator.clipboard.writeText(this.data.charges[0].qr_code_copy_and_paste);
      this.$store.commit(
        "SET_SNACKBAR_SUCCESS_MESSAGE",
        "Código PIX copiado com sucesso!"
      );
    },
  },
};
</script>
