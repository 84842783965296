<template>
  <div class="order-approved-content">
    <h1>Agora é só pagar o boleto abaixo:</h1>
    <v-divider></v-divider>
    <div class="active-installment">
      <div class="installment">
        <p>{{ dueAt }}</p>
        <p>R$ {{ formatterHelper.formatMoney(selectedInstallment ? selectedInstallment.total_value: data.total_amount) }}</p>
      </div>
      <div class="active-installment-actions" style="justify-content: center;">
        <v-btn class="btn btn-primary" @click="copy"
          :style="!disableButton ? { 'background-color': 'rgba(var(--theme-primaria)) !important' } : {}">
          <i class="fal fa-copy"></i>
          Copiar código de barras
        </v-btn>
        <v-btn class="btn btn-primary"
          :style="!disableButton ? { 'background-color': 'rgba(var(--theme-primaria)) !important' } : {}"
          @click="download">
          <i class="fal fa-download"></i>
          Baixar boleto
        </v-btn>
        
      </div>
      <!-- <img v-if="data.charges[0].barcode" :src="data.charges[0].barcode" alt="" srcset=""> -->
      <barcode :line="data.charges[0].line"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Barcode from "@/views/web/checkout/OrderTypes/components/Barcode.vue";

import FormatterHelper from "@/scripts/helpers/formatter.helper";
import CheckoutService from "@/scripts/services/checkout.service";

export default {
  props: ["params", "data"],
  components: {
    'barcode': Barcode
  },
  data() {
    return {
      formatterHelper: new FormatterHelper(),
      checkoutService: new CheckoutService()
    };
  },
  computed: {
    ...mapGetters([
      "checkoutValue",
      "selectedInstallment"
    ]),
    dueAt() {
      return new Date(this.data.charges[0].due_at).toLocaleDateString('en-GB');
    },
    barcode() {
      const data = this.data.charges[0].line;
      return data.replaceAll('.','').replaceAll(' ','');
    }
  },
  methods: {
    download() {
      this.checkoutService.getTicket(this.data.charges[0].url).then((blob) => {
        const filename = `boleto-${new Date().toISOString().replace(/\D/g, '')}.pdf`;

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.setAttribute('download', filename);
        link.href = url;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      })
    },
    copy() {
      navigator.clipboard.writeText(this.data.charges[0].line)
      this.$store.commit(
        "SET_SNACKBAR_SUCCESS_MESSAGE",
        "Código de barras copiado com sucesso!"
      );
    }
  },
};
</script>
